<template>
  <div class="container mt-6">
    <div class="section">
      <div class="columns">
        <div class="column">
          <h1 class="title">Teams</h1>
        </div>
        <div class="column is-narrow">
          <router-link to="/teams/create" class="button is-dark">
            Add team
          </router-link>
        </div>
      </div>
      <hr />
      <div v-if="isLoading">
        <span class="icon-text">
          <span class="icon">
            <i class="fas fa-spin fa-sync-alt"></i>
          </span>
          <span>Loading...</span>
        </span>
      </div>
      <div v-else-if="!teams?.length">No teams yet</div>
      <table v-else class="table is-bordered is-fullwidth is-narrow mt-4">
        <tbody>
          <tr v-for="team in teams" :key="team.id">
            <td
              @click="$router.push(`/teams/${team.id}`)"
              class="is-clickable is-vcentered"
            >
              {{ team.name }}
            </td>
            <td class="is-narrow p-0">
              <button
                type="button"
                class="button is-danger is-inverted"
                @click.capture.prevent="removeTeam(team.id)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import { useApi } from '@/api'
import api from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const teamsRes = useApi(api => api.teams.getTeams())
    const isLoading = teamsRes.isLoading
    const teams = teamsRes.data

    function removeTeam(teamId: number) {
      api.teams.removeTeam(teamId).then(teamsRes.refresh)
    }

    return {
      isLoading,
      teams,
      removeTeam
    }
  }
})
</script>

<style scoped lang="scss"></style>

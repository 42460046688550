
import { useApi } from '@/api'
import api from '@/api'
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const teamsRes = useApi(api => api.teams.getTeams())
    const isLoading = teamsRes.isLoading
    const teams = teamsRes.data

    function removeTeam(teamId: number) {
      api.teams.removeTeam(teamId).then(teamsRes.refresh)
    }

    return {
      isLoading,
      teams,
      removeTeam
    }
  }
})
